import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router, public auth: AuthService) {}
  permissions = {
    Developer: [
      "/home",
      "/driver",
      "/race-manager",
      "/import-generator",
      "/load-session",
      "/viewer",
      "/energies",
      "/raceplot",
      "/supersecret",
    ],
    Strategist: [
      "/home",
      "/driver",
      "/viewer",
      "/energies",
      "/raceplot",
      "/race-manager",
    ],
    Envision: ["/home", "/driver", "/viewer", "/energies", "/raceplot"],
    "Race Engineer": ["/home", "/driver", "/viewer", "/energies", "/raceplot"],
    "Performance Engineer": [
      "/home",
      "/driver",
      "/viewer",
      "/energies",
      "/raceplot",
    ],
    "Ops Room": ["/home", "/driver", "/viewer", "/energies", "/raceplot"],
    "Controls Engineer": [
      "/home",
      "/driver",
      "/viewer",
      "/energies",
      "/raceplot",
    ],
    "Radio Listener": ["/home", "/driver", "/viewer", "/energies", "/raceplot"],
    "Design Engineer": [
      "/home",
      "/driver",
      "/viewer",
      "/energies",
      "/raceplot",
    ],
    Viewer: ["/home", "/viewer", "/energies", "/raceplot"],
  };

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      if (!this.auth.isAuthenticated) {
        this.auth
          .authenticateJwt()
          .then((res) => {
            resolve(this.isRouteAllowed(state.url));
          })
          .catch((err) => {
            this.router.navigateByUrl("/login");
            resolve(false);
          });
      } else {
        resolve(this.isRouteAllowed(state.url));
      }
    });
  }

  isRouteAllowed(route: string) {
    let role = this.auth.role;
    if (role) {
      if (this.permissions.hasOwnProperty(role)) {
        if (this.permissions[role].includes(route)) {
          return true;
        } else {
          this.router.navigateByUrl("/home");
          return false;
        }
      } else {
        this.auth.logout();
        return false;
      }
    } else {
      this.auth.logout();
      return false;
    }
  }
}
